<template>
  <div style="background: white">
    <Head></Head>
    <div class="page">
      <h1>Condiciones generales de contratación</h1>
      <h2>1. PARTES Y ACEPTACIÓN CONDICIONES GENERALES</h2>
      <p>
        De una parte, el proveedor de los productos, ELOSUNG EUROPE, S.A (en
        adelante, “ELOSUNG EUROPE”), A-05270319 y domicilio social en Calle
        Carrer de Francesc Teixidó, 43, 08918 Badalona, Barcelona, dirección de
        correo electrónico soporte@rakumart.es, (en adelante también el
        prestador), siendo titular del sitio web
        <a href="/">www.rakumart.es</a> , expone el documento contractual que
        regirá la contratación de productos a través del sitio web reseñado.
      </p>
      <p>
        Y de otra, el Usuario – Cliente (en adelante el Usuario), registrado en
        el sitio web mediante un nombre de usuario y contraseña, sobre los que
        tiene responsabilidad plena de uso y custodia, siendo responsable de la
        veracidad de los datos personales facilitados al prestador.
      </p>
      <p><b>ACEPTAN</b> el presente documento, y conlleva que el usuario:</p>
      <ul>
        <li>Ha leído, entiende y comprende lo aquí expuesto.</li>
        <li>
          Que es una persona mayor de edad y con capacidad suficiente para
          contratar.
        </li>
        <li>Que asume todas las obligaciones aquí dispuestas.</li>
        <li>
          Que ha leído y acepta las presentes
          <b>condiciones generales de contratación</b> desde el momento que
          adquiera cualquier producto ofertado.
        </li>
      </ul>
      <p>
        Este documento puede ser impreso y almacenado por los Usuarios. El
        prestador pone a disposición de éstos la dirección de e-mail
        soporte@rakumart.es para que planteen cualquier duda acerca de estas
        condiciones.
      </p>
      <p>
        Las presentes condiciones tendrán un periodo de validez indefinido y
        serán aplicables a todas las contrataciones realizadas a través del
        sitio web del prestador.
      </p>
      <p>
        El prestador se reserva el derecho de modificar unilateralmente dichas
        Condiciones Generales, sin que ello pueda afectar a los bienes o
        promociones que fueron adquiridos previamente a la modificación, con el
        fin de mejorar los productos ofrecidos. En todo caso, antes de comprar
        los productos han de consultarse estas condiciones generales.
      </p>
      <p>
        Es aconsejable que guarde una copia de los datos contenidos en los
        productos comprados. ELOSUNG EUROPE no se responsabiliza de cualquier
        pérdida de datos, archivos o de cualquier daño que resultara de un fallo
        de copia de seguridad por parte del titular de los datos.
      </p>
      <p>
        EL prestador no se hace responsable de las consecuencias que pudieran
        resultar de una utilización inadecuada de los productos a la venta en la
        web.
      </p>
      <p>
        La responsabilidad civil de ELOSUNG EUROPE por los productos
        suministrados queda limitada al importe de los mismos, el usuario
        renuncia a reclamar cualquier responsabilidad al prestador por cualquier
        concepto en cualquier caso de insatisfacción de los productos adquiridos
        en la web www.rakumart.es, así como posibles fallos, lentitud de acceso
        o errores en el acceso a la web, incluyéndose perdidas de datos u otro
        tipo de información que pudiera existir en el ordenador o red del
        usuario que acceda a la web.
      </p>
      <h2>2. OBJETO DEL CONTRATO</h2>
      <p>
        <b>2.1 Ámbito de aplicación</b>: El presente contrato tiene por objeto
        regular la relación contractual de compra-venta nacida entre el
        prestador y el usuario en el momento en que éste acepta estas
        condiciones de compra durante el proceso de contratación online
        verificando la casilla correspondiente. Estas condiciones generales se
        aplicarán a partir del día en que se realice el pedido.
      </p>
      <p>
        La relación contractual de compraventa conlleva la entrega, a cambio de
        un precio determinado y públicamente expuesto a través del sitio web, de
        un producto concreto.
      </p>
      <p>
        <b>2.2 Territorio de aplicación</b>: La tienda virtual de
        <a href="/">www.rakumart.es </a> está activa en todo el territorio
        nacional.
      </p>
      <p>
        <b>2.3 Capacidad para contratar</b>: Para poder realizar un pedido
        deberá ser mayor de edad y tener capacidad para contratar.
      </p>
      <p>
        <b>2.4 Aceptación del cliente</b>: La validación de un pedido a través
        de la web se realiza por correo electrónico e implica, asimismo, la
        aceptación automática de las condiciones generales. Estas condiciones
        están disponibles en la web www.rakumart.es, o, si lo desea y solicita,
        podemos ponerla a su disposición por correo electrónico a la dirección
        arriba indicada.
      </p>
      <h2>3. PRECIOS</h2>
      <p>
        Todos los precios mostrados en la página web de ELOSUNG EUROPE, en
        www.rakumart.es, no incluyen el IVA ni gastos de envío.
      </p>
      <p>
        Si en un futuro se realizan entregas internacionales y el pedido se va a
        enviar a una dirección de entrega fuera de la Unión Europea, tenga en
        cuenta que el IVA se deducirá cuando realice la compra, o antes,
        dependiendo del país en el que se vaya a entregar el producto.
        Observación: si el pedido se va a enviar a una dirección de entrega que
        se encuentra fuera de la UE, es posible que esté sujeto a derechos de
        importación e impuestos cuyo pago deberá efectuarse cuando el envío
        llegue a la dirección de entrega. El CLIENTE será responsable del pago
        de estos importes correspondientes a derechos de importación e
        impuestos. Se quiere destacar que ELOSUNG EUROPE no tiene control sobre
        estos recargos. ELOSUNG EUROPE recomienda recabar más información en el
        Servicio de Aduanas. El precio no incluye los gastos de envío, estos se
        aplicarán a la hora de realizar la compra del producto.
      </p>
      <p>
        Los precios de nuestros productos están sujetos a cambios sin previo
        aviso. Nos reservamos el derecho de modificar o discontinuar el Servicio
        (o cualquier parte o contenido del mismo) sin previo aviso en cualquier
        momento. No seremos responsables ante usted ni ante ningún tercero por
        ninguna modificación, cambio de precio. El cambio de precio no afectará
        a los pedidos realizados anteriormente.
      </p>
      <h2>4. REALIZACIÓN DEL PEDIDO</h2>
      <p>
        ELOSUNG EUROPE tiene el derecho de cambiar los precios y corregir los
        precios incorrectos en todos los pedidos, así como en las ventas
        finales. Si un precio es incorrecto, esto puede obligar a ELOSUNG EUROPE
        a cancelar la compra y a devolver la cantidad pagada de la mejor manera.
        Esta operación se puede realizar en cualquier momento. Los pedidos
        realizados a nombre de otra persona sin su consentimiento se comunicarán
        a las autoridades competentes.
      </p>
      <p>
        La realización del pedido puede estar supeditada a la identificación y
        registro en la página web mediante nombre y usuarios.
      </p>
      Se informa que de conformidad con lo exigido por el art. 27 de la Ley
      34/2002 de Servicios de la Sociedad de la Información y del Comercio
      Electrónico, el procedimiento de contratación seguirá los siguientes
      pasos:
      <ul class="dian">
        <li>Registrarte mediante nombre de usuario y contraseña.</li>
        <li>
          Escoger el producto haciendo clic en “añadir al carrito”, y se añadirá
          automáticamente en el “carro”.
        </li>
        <li>
          Si se desean agregar más productos, tendrá la opción de agregar dichos
          productos. Si por error ha añadido alguno que no quisiera, deberá
          clicar en la opción de eliminar de la cesta de la compra.
        </li>
        <li>
          Escogido/s el/los producto/s, deberá clicar en la pestaña “continuar”
          para realizarla compra. Al clicar en esta opción, aparecerá la
          confirmación de la compra (resumen del pedido realizado).
        </li>
        <li>
          Deberá aceptar mediante el check en las casillas la Política de
          Privacidad y las presentes Condiciones Generales de Compra. Una vez
          cumplimentado éste, se dará la opción de continuar con el pago donde
          se seleccionará la forma de pago.
        </li>
        <li>
          Para finalizar el proceso, deberá clicar en el botón Finalizar el
          pedido.
        </li>
        <li>
          Una vez finalizado el pedido recibirá en la dirección de correo
          electrónico indicada un e-mail de confirmación del pedido.
        </li>
      </ul>
      <p>
        El proceso de compra puede estar sujeto a cambios. La plataforma de
        contratación del prestador informará al usuario, una vez finalizado el
        procedimiento de contratación, vía correo electrónico respecto a todas
        las características, precio, formas de transporte, fecha de contratación
        y estimación de entrega del producto comprado. Por favor, revise los
        controles de spam y de correo no deseado de su bandeja de entrada del
        correo electrónico y verifique siempre que los datos de entrega y
        personales son correctos.
      </p>
      <p>
        Si hubiera cualquier tipo de error en la dirección indicada o en
        cualquier otro punto del pedido, deberá notificarlo de inmediato a la
        dirección de e-mail que aparecerá en la web para proceder a la
        subsanación de este error.
      </p>
      <p>
        Ante cualquier duda puede contactar con nuestro servicio de Atención al
        cliente a través de cualquiera de los métodos que se facilitan en la
        web. ELOSUNG EUROPE proporcionará Atención al Cliente de forma GRATUITA
        a través de nuestro email de contacto soporte@rakumart.es. Si elige otro
        medio de comunicación alternativo tendrás que hacerte cargo del coste
        particular del mismo.
      </p>
      <h2>5. DERECHO DE DESISTIMIENTO</h2>
      <p>
        En el caso concreto de ELOSUNG EUROPE, al realizar importaciones
        específicas a petición del cliente desde un tercer país fuera del
        espacio económico europeo, solo se podrá llevar a cabo la cancelación de
        la compra bajo las políticas que ofrezcan los fabricantes. El cliente
        puede solicitar a ELOSUNG EUROPE que actúe como intermediario en las
        negociaciones con el vendedor, sin embargo, ELOSUNG EUROPE no asume
        responsabilidad por los resultados finales de dichas negociaciones.
        Todos los costos asociados con la resolución del problema serán
        responsabilidad del cliente. (El cliente deberá confirmar con el
        vendedor la posibilidad de devolución o cambio del producto, y será
        responsable de los gastos de envío nacionales que se generen), no siendo
        relevante la cantidad de la misma, o la mercancía está defectuosa.
      </p>
      <p>
        Envíenos un correo electrónico a
        <a href="mailto:soporte@rakumart.es">soporte@rakumart.es</a> le
        proporcionaremos las instrucciones para devolver el producto. El horario
        de atención al cliente será de lunes a viernes de 09:00h a18:00h. Para
        la cancelación del pedido o la ampliación del mismo se podrá utilizar el
        chat instalado en la plataforma.
      </p>
      <p>
        En caso de devolución por defecto en la mercancía se deberá devolver el
        producto con todos los elementos con los que le fue entregado y que no
        denote un uso inadecuado del bien o diferente de la naturaleza propia
        del mismo a la dirección que le hemos facilitado.
      </p>
      <p>
        Tenga en cuenta que no se producirá la devolución de la mercancía si:
      </p>
      <ul class="dian">
        <li>
          Fallos o daños causados por el uso indebido o descuido (golpes,
          abolladuras, aplastamientos, etc.).
        </li>
        <li>
          Fallos o daños causados por una reparación o modificación
          injustificable.
        </li>
        <li>
          Fallos o daños causados por fuego o agua, o un desastre natural como
          un terremoto.
        </li>
        <li>
          Los cambios estéticos que se produzcan durante el uso y desgaste
          normal y el envejecimiento.
        </li>
        <li>
          En caso de que la tienda y la fecha de compra no se indiquen en la
          garantía, o si esta información ha sido reescrita.
        </li>
      </ul>
      <p>
        Esta garantía le asegura que usted puede recibir un reemplazo gratuito
        de conformidad con los términos establecidos en el presente documento y
        dentro del término indicado, y no podrá restringir ningún derecho legal
        del cliente.
      </p>
      <h2>6. CONFIRMACIÓN</h2>
      <p>
        Una vez que hayamos recibido su pedido, le enviaremos una confirmación a
        la dirección de correo electrónico. Por lo tanto, es importante que
        indique su dirección de correo electrónico real al realizar el pedido.
        Guarde este correo electrónico para poder contactar con el servicio de
        atención al cliente si lo necesita. La confirmación del pedido (recibo)
        también sirve como una garantía de prueba de compra.
      </p>
      <h2>7. PAGO</h2>
      <p>
        Los pedidos que realice en nuestra tienda electrónica se pueden pagar
        mediante:
      </p>
      <ul style="margin-bottom: 35px">
        <li>Tarjeta de crédito/débito</li>
        <li>Transferencia bancaria</li>
      </ul>
      <p>
        Te garantizamos los altos estándares de seguridad gracias al protocolo
        criptográfico más avanzado, el SSL, Secure Sockets Layer. Este sistema
        nos permite proteger sus datos personales de accesos no autorizados.
      </p>
      <p>
        El pago mediante transferencia bancaria será realizado a la cuenta
        bancaria propiedad de ELOSUNG EUROPE.
      </p>
      <p>La moneda de pago será siempre el EURO.</p>
      <h2>8. INFORMACIÓN PERSONAL</h2>
      <p>
        Al realizar una compra, ELOSUNG EUROPE guardará parte de sus datos para
        completar su compra. ELOSUNG EUROPE trata confidencialmente toda la
        información personal. Usted tiene derecho a solicitar acceso a sus datos
        y en caso de que contengan errores podrá realizar los cambios necesarios
        o eliminarlos. ELOSUNG EUROPE también puede usar cookies para, por
        ejemplo, mejorar su experiencia en el sitio web. Puede obtener más
        información sobre el modo en que ELOSUNG EUROPE usa sus datos personales
        en la política de privacidad que rige en el sitio web
        <a href="/">www.rakumart.es </a>.
      </p>
      <h2>9. PRODUCTO</h2>
      <p>
        ELOSUNG EUROPE tiene el derecho de ajustar los precios, añadir costes
        adicionales y cambiar la información sobre productos, como las imágenes
        de los productos y ofertas sin previo aviso. Las imágenes de los
        productos y los textos de información disponibles reflejan el producto
        en la medida de lo posible. Nos reservamos el derecho por los errores
        que pueden aparecer en la página y no podemos garantizar que todas las
        imágenes reflejen con exactitud la verdadera apariencia de los
        productos. Las imágenes pueden variar en función de los ajustes de color
        e imagen de su ordenador. Todas las imágenes deben considerarse
        solamente ilustraciones y no garantizan la apariencia ni las
        características.
      </p>
      <p>
        Los productos ofertados en la página web corresponden a una variedad de
        productos ofertados, pudiéndose en un futuro ofertar productos o
        servicios diferentes.
      </p>
      <h2>10. GARANTÍAS APLICABLES Y SERVICIOS POSTVENTA</h2>
      <p>
        <b>10.1 Garantía</b>: Todos los productos ofertados cuentan con un
        periodo de garantía de 3 años, conforme a los criterios y condiciones
        descritas en el Real Decreto Legislativo 1/2007, de 16 de noviembre, por
        el que se aprueba el texto refundido de la Ley General para la Defensa
        de los Consumidores y Usuarios y otras leyes complementarias, salvo
        casos excepcionales en los que puede ser de distinta duración.
      </p>
      <p>
        En caso de que la Ley de Garantía establezca que el producto debe ser
        enviado al fabricante para gestionar dicha garantía, el equipo de
        ELOSUNG EUROPE le indicará cómo proceder a través de correo electrónico.
        El derecho de garantía será aplicable siempre que se trate de un defecto
        de fábrica y no del uso inadecuado del artículo o por desgaste normal de
        uso.
      </p>
      <p>
        <b>10.2 Productos defectuosos</b>: En caso de producto defectuoso,
        ELOSUNG EUROPE deberá proceder a la reparación, sustitución, rebaja del
        precio o resolución del contrato (según corresponda), gestiones que
        serán gratuitas para el usuario. ELOSUNG EUROPE deberá responde de las
        faltas de conformidad que se manifiesten en un plazo de tres años desde
        la entrega, siempre y cuando informe a ELOSUNG EUROPE deberá de la falta
        de conformidad en el plazo de dos meses desde que tuvo conocimiento de
        ella. En cuanto a la devolución por error en el pedido por parte de
        ELOSUNG EUROPE deberá, cuando el producto no se corresponda al del
        pedido, debido a algún error imputable a ELOSUNG EUROPE el usuario
        deberá comunicarlo por mail a la compañía tan pronto como sea posible;
        ELOSUNG EUROPE correrá con los gastos de recogida del producto enviado
        por error y entrega del correspondiente al del pedido realizado.
      </p>
      <p>
        <b>10.3 Supuestos de anulación de garantía</b>: La garantía no se aplica
        en los supuestos siguientes:
      </p>
      <ul class="dian">
        <li>
          Deterioro creado por adaptaciones, ajustes o modificaciones realizadas
          sobre un producto sin acuerdo escrito con el fabricante,
        </li>
        <li>
          Gastos relativos al mantenimiento, reparación o cambio por piezas por
          desgaste normal,
        </li>
        <li>Averías y deterioro de los accesorios,</li>
        <li>
          Deterioro creado por una mala utilización del producto, uso
          inapropiado, no respeto hacia las instrucciones de uso en lo que
          respecta a su utilización y mantenimiento o una instalación
          inapropiada,
        </li>
        <li>Daños generados por personas no acreditadas o el usuario,</li>
        <li>
          Daños causados por accidentes, rayos, inundaciones, incendios o
          cualquier otra causa externa que no sea responsabilidad de ELOSUNG
          EUROPE,
        </li>
        <li>
          Uso del producto fuera del marco familiar y privado, por ejemplo, en
          el uso profesional intensivo, o
        </li>
        <li>
          Productos ya reparados por Servicios de Reparación ajenos ELOSUNG
          EUROPE.
        </li>
      </ul>
      <p>
        <b>10.4 Sustitución de un producto irreparable</b>: En el caso de que el
        producto sea considerado irreparable, no pueda ser reparado o el coste
        de la reparación sea desproporcionado respecto a su valor, se sustituirá
        por otro exactamente igual. De no ser posible esto último, le
        ofreceremos un producto de características idénticas.
      </p>
      <p>
        La sustitución de su producto dará por concluido su contrato de
        compraventa y extensiones de garantía si las hubiera, ya que al adquirir
        su nuevo producto éste se beneficiará de un nuevo contrato al tratarse
        de una nueva compra.
      </p>
      <p>
        <b>10.5 Plazos de garantía</b>: Los periodos que se distinguen en la
        legislación vigente son:
      </p>
      <p>
        – Periodo desde su adquisición (en el caso de haber realizado la compra
        presencial) o posesión (en el caso de haber realizado una compra online)
        hasta los 24 meses (dos años).
      </p>
      <p>
        – Periodo desde los 2 años hasta los 3 años de su adquisición (en el
        caso de haber realizado una compra presencial) o posesión (en el caso de
        haber realizado una compra online). Pasado el plazo primero de 2 años el
        vendedor podrá exigir al consumidor que demuestre que el fallo existía
        de origen.
      </p>
      <p>A continuación, se especifican los periodos con más detalle.</p>
      <p>
        Periodo desde su adquisición o posesión hasta los 2 años: De conformidad
        con lo previsto en la LGCYU, se aplicará la garantía sobre los artículos
        siempre que el artículo cumpla las siguientes características a su
        devolución:
      </p>
      <p>
        – Que el producto se encuentre en los primeros dos años desde su
        adquisición (en el caso de haber comprado directamente el artículo en
        nuestras instalaciones), o desde la posesión del producto (en el caso de
        haber adquirido el artículo de forma online).
      </p>
      <p>
        – Que el producto haya experimentado un correcto uso, se hayan seguido
        las recomendaciones del fabricante y su manipulación haya sido correcta.
      </p>
      <p>
        – Que con la devolución del producto también se devuelvan los accesorios
        y que se entregue en el mismo estado en el que ELOSUNG EUROPE se lo
        envió, por ejemplo, en el caso de las baterías, se deberán devolver
        cargadas.
      </p>
      <p>
        – Que el producto cuente con su embalaje y documentos originales. En
        caso de no disponer del embalaje original, se requerirá uno similar en
        cuanto a nivel de seguridad y protección para el producto al facilitado
        por ELOSUNG EUROPE en el momento de la compra.
      </p>
      <p>
        – Que los productos no cuenten con muestras evidentes de mala
        manipulación, daño o rotura.
      </p>
      <p>
        No debe pasar más de dos meses entre que el cliente experimenta un
        problema con su producto hasta que lo notifica a ELOSUNG EUROPE, en caso
        contrario el producto pierde su garantía.
      </p>
      <p>
        <b
          >Periodo desde los 2 años hasta los 3 años desde su adquisición o
          posesión</b
        >: Aquellos productos que presenten irregularidades en su funcionamiento
        durante este periodo de tiempo se podrá ejercer el derecho de garantía
        siempre que junto con el producto defectuoso se envíe un informe de
        peritaje industrial que afirme que el producto durante su vida útil fue
        utilizado correctamente y que cualquier problema en su funcionamiento es
        consecuencia de un defecto de fabricación arrastrado en el tiempo.
      </p>
      <p>
        No debe pasar más de dos meses entre que el cliente experimenta un
        problema con su producto hasta que lo notifica a ELOSUNG EUROPE, en caso
        contrario el producto pierde su garantía.
      </p>
      <h2>11. LOGISTICA, ENTREGA Y CANCELACIONES</h2>
      <p>
        ELOSUNG EUROPE realiza envíos a todo el territorio nacional (España).
        Los plazos de entrega son estimatorios, no siendo responsable ELOSUNG
        EUROPE de posibles retrasos en los plazos estimados, no siendo válida la
        cancelación del pedido por retraso en los plazos. Una vez realizada la
        compra por la plataforma web www.rakumart.es se produce la cotización
        estimada de envío en 24h posterior pago de la mercancía, una vez
        realizado dicho pago la mercancía se transporta a los almacenes
        titularidad de ELOSUNG EUROPE donde se realizará un control de calidad y
        manipulación de la mercancía, una vez observado el buen estado de la
        mercancía se realizará el pago del envío y la entrega. Antes de realizar
        la entrega se agendará entre cliente y ELOSUNG EUROPE el modo de
        entrega.
      </p>
      <p>
        Las devoluciones de la compra estarán sujetas a las políticas de
        devolución establecidas por el fabricante solo se podrá llevar a cabo la
        cancelación de la compra bajo las políticas que ofrezcan los
        fabricantes. El cliente puede solicitar a ELOSUNG EUROPE que actúe como
        intermediario en las negociaciones con el vendedor, sin embargo, ELOSUNG
        EUROPE no asume responsabilidad por los resultados finales de dichas
        negociaciones. Todos los costos asociados con la resolución del problema
        serán responsabilidad del cliente. (El cliente deberá confirmar con el
        vendedor la posibilidad de devolución o cambio del producto, y será
        responsable de los gastos de envío nacionales que se generen), no siendo
        relevante la cantidad de la misma, o la mercancía está defectuosa.
      </p>
      <h2>12. DISPONIBILIDAD DE PRODUCTOS:</h2>
      <p>
        Puede haber ocasiones en que sea imposible suministrar todos los
        productos debido a que éstos no estén en stock o disponibles en el
        momento de la compra. En estos casos, personal de ELOSUNG EUROPE se
        pondrá en contacto con Ud. para darle a elegir entre las siguientes
        opciones:
      </p>
      <ul>
        <li>
          a) elección de un producto alternativo, con características similares
          al producto a reemplazar;
        </li>
        <li>
          b) la comunicación de su disponibilidad, por si prefiere esperar a que
          dicho producto vuelva a estar disponible en la tienda.
        </li>
      </ul>
      <p>
        Si declina nuestras sugerencias, el pedido relacionado con estos
        productos será cancelado y se le devolverá cualquier suma que hubiera
        satisfecho por los mismos.
      </p>
      <h2>13. CONDICIONES DE RECLAMACIONES</h2>
      <p>
        Es importante que el cliente compruebe con atención los productos cuando
        reciba el envío con el fin de verificar que los productos no estén
        dañados y sean correctos. Si su producto sufre daños durante el
        transporte, ya sea un daño visible u oculto, debe informar de ello lo
        antes posible. Si al recibir su artículo advierte que está dañado o no
        coincide con el que había pedido, también debe ponerse en contacto con
        nosotros en un plazo máximo de 30 días a partir de la fecha de recepción
        del producto.
      </p>
      <p>
        Todas las reclamaciones deberán dirigirse a nuestro servicio de atención
        a clientes a través de nuestro chat que encontrará en la página web o
        mediante correo electrónico a la dirección indicada en el punto 1 de las
        presentes condiciones generales de contratación, facilitándonos el
        número de pedido y la razón de su reclamación y nos pondremos en
        contacto con usted a la mayor brevedad posible. Haga lo mismo en el caso
        de devoluciones. Los productos defectuosos devueltos a ELOSUNG EUROPE
        deben tratarse como si no tuvieran defectos. Es extremadamente
        importante que en todas las devoluciones el producto se embale de tal
        forma que no pueda dañarse durante el transporte. ELOSUNG EUROPE tiene
        el derecho a sustituir los productos defectuosos por otros productos del
        mismo modelo. Si el artículo está descatalogado, el cliente recibirá un
        reembolso del importe completo pagado. En el caso de que al producto
        devuelto no se le encuentren errores o fallos, ELOSUNG EUROPE volverá a
        enviar el producto al cliente.
      </p>
      <h2>14. RESPONSABILIDAD</h2>
      <p>
        La responsabilidad de ELOSUNG EUROPE por errores en la entrega está
        limitada a las condiciones de venta en vigor. ELOSUNG EUROPE no asume
        ninguna responsabilidad directa o indirecta, por ejemplo, pero sin
        limitarse a, incompatibilidad, demoras en la entrega, tiempo de
        inactividad, pérdida de datos, trabajos adicionales u otros perjuicios
        económicos. La responsabilidad civil de ELOSUNG EUROPE por los productos
        y servicios suministrados queda limitada al importe de los mismos, el
        usuario renuncia a reclamar cualquier responsabilidad al prestador por
        cualquier concepto en cualquier caso de insatisfacción de los productos
        adquiridos en la página web, así como posibles fallos, lentitud de
        acceso o errores en el acceso a la página web, incluyéndose perdidas de
        datos u otro tipo de información que pudiera existir en el ordenador o
        red del usuario que acceda a la plataforma.
      </p>
      <h2>15. FUERZA MAYOR</h2>
      <p>
        ELOSUNG EUROPE estará exento de responsabilidad por los daños y
        perjuicios y otras sanciones si la ejecución del contrato se ve
        impedida, obstaculizada o demorada por circunstancias que escapan a
        nuestro control. Como factores de liberación a tener en cuenta deben
        incluirse la intervención del gobierno, legislación nueva / modificada,
        ausencia de estado, guerra, incendios, inundaciones, disturbios
        laborales, prohibiciones, restricciones, sabotaje, deficiencias del
        transporte o condiciones meteorológicas o falta de entrega por parte de
        los proveedores, y la exposición de la empresa a actividad criminal que
        afecte a sus actividades.
      </p>
      <h2>16. DERECHOS DE PROPIEDAD INTELECTUAL</h2>
      <p>
        Todos los derechos de propiedad intelectual, tales como marcas
        comerciales y derechos de autor, que aparezcan en el sitio web y en el
        material publicado, son propiedad de ELOSUNG EUROPE o de sus
        subsidiarias o licenciantes. Cualquier uso del sitio o de su contenido,
        incluyendo la copia o almacenamiento de dicho contenido, en su totalidad
        o en parte, para fines distintos al uso personal, no comercial, queda
        prohibido sin el permiso de ELOSUNG EUROPE.
      </p>
      <h2>17. FRAUDE</h2>
      <p>
        Todos los fraudes se denunciarán a las autoridades y ELOSUNG EUROPE se
        reserva el derecho de cancelar la compra si existe cualquier sospecha de
        ello.
      </p>
      <h2>18. RESCISIÓN</h2>
      <p>
        ELOSUNG EUROPE se reserva el derecho a cancelar su cuenta y/o prohibir
        que use su sitio web www.rakumart.es a su entera discreción. Esto puede
        ocurrir si, por ejemplo, ELOSUNG EUROPE sospecha que ha incumplido estas
        Condiciones de uso. Esta rescisión puede realizarse sin previo aviso.
      </p>
      <h2>19. MODIFICACIONES</h2>
      <p>
        ELOSUNG EUROPE podrá revisar estas Condiciones de uso periódicamente.
        Siempre que realice un pedido con nosotros, quedará sujeto a las
        condiciones generales en vigor en el momento de formalizar el contrato
        entre usted y ELOSUNG EUROPE. Por ello, debe visitar periódicamente esta
        página para revisar las condiciones actuales a las que está sujeto.
      </p>
      <h2>20. NULIDAD E INEFICACIA DE LAS CLAUSULAS:</h2>
      <p>
        Si cualquier cláusula incluida en estas Condiciones Generales fuese
        declarada, total o parcialmente, nula o ineficaz, tal nulidad o
        ineficacia afectará tan sólo a dicha disposición o a la parte de la
        misma que resulte nula o ineficaz, subsistiendo las Condiciones
        Generales en todo lo demás, teniéndose tal disposición, o la parte de la
        misma que resultase afectada, por no puesta.
      </p>
      <h2>21. LEY APLICABLE Y JURISDICCIÓN:</h2>
      <p>
        Estas condiciones se regirán o interpretarán conforme a la legislación
        española en aquello que no esté expresamente establecido. El prestador y
        el usuario acuerdan someter cualquier controversia que pudiera
        suscitarse de la prestación de los productos o servicios objeto de estas
        Condiciones, a los Juzgados y Tribunales del domicilio del prestador (si
        es persona jurídica) o del usuario (si es persona física).
      </p>
      <p>
        En el caso de que el usuario tenga su domicilio fuera de España, el
        prestador y el usuario, renuncian expresamente a cualquier otro foro,
        sometiéndose a los Juzgados y Tribunales de la ciudad de Barcelona
        (España).
      </p>
    </div>
    <Foot style="width: 100%"></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
export default {
  data() {
    return {};
  },
  components: { Head, Foot },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.page {
  width: 750px;
  margin: 30px auto;
  padding: 10px;
  h1 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1;
  }
  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 14.4px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 14.4px;
  }
  ul {
    li {
      margin-left: 40px;
      list-style: decimal;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
    }
    &.dian {
      li {
        list-style: disc;
      }
    }
  }
}
a {
  color: #cc3366;
  &:hover {
    color: black;
  }
}
</style>
